/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import AnalyticsService from "./src/utils/analytics-service"
import MarketingService from "./src/utils/marketing-service"

export { default as wrapRootElement } from './src/utils/store/wrapper'

const onRouteUpdate = (state, page, pages) => {
    const analyticsService = new AnalyticsService()
    analyticsService.initialize()
    analyticsService.trackPage(state.pathname)

    const marketingService = new MarketingService()
    marketingService.initialize()
    marketingService.trackPage()
}

export { onRouteUpdate }