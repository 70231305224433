function setEmbedCookiesReducer(state = false, action) {
    switch (action.type) {
        case "ENABLE_EMBED_COOKIES":
            return true
        case "DISABLE_EMBED_COOKIES":
            return false
        default:
            return state
    }
}

export default setEmbedCookiesReducer