import reducers from "./reducers"
import { createStore } from "redux"

let store

if (typeof window !== "undefined") {
    store = createStore(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
} else {
    store = createStore(reducers)
}

export default store