import ReactGA from "react-ga"
import Cookies from "js-cookie"

class AnalyticsService {
    constructor() {
        this.analyticsInstance = null
        this.isInitialized = false
    }

    initialize() {
        if (Cookies.get("allowTrackingCookies") === "true" && typeof window !== "undefined") {
            this.analyticsInstance = ReactGA
            // this.analyticsInstance.initialize('UA-187130696-1')
            // this.analyticsInstance.set({ anonymizeIp: true })
            this.isInitialized = true
        }
    }

    trackPage(path) {
        if (this.isInitialized) {
            this.analyticsInstance.pageview(path)
        }
        return true
    }

    trackModal(path) {
        if (this.isInitialized) {
            this.analyticsInstance.modalview(path)
        }
        return true
    }
}

export default AnalyticsService