function setCookieSaveStateReducer(state = false, action) {
    switch (action.type) {
        case "SAFED_COOKIES":
            return true
        case "UNSAFED_COOKIES":
            return false
        default:
            return state
    }
}

export default setCookieSaveStateReducer