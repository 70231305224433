import setEmbedCookiesReducer from "./setEmbedCookies"
import setMarketingCookiesReducer from "./setMarketingCookies"
import setTrackingCookiesReducer from "./setTrackingCookies"
import { combineReducers } from "redux"
import setCookieSaveStateReducer from "./setCookieSaveState"
import setBasicCookiesReducer from "./setBasicCookies"

const allReducers = combineReducers({
    cookieSaveState: setCookieSaveStateReducer,
    basicCookies: setBasicCookiesReducer,
    embedCookies: setEmbedCookiesReducer,
    marketingCookies: setMarketingCookiesReducer,
    trackingCookies: setTrackingCookiesReducer
})

export default allReducers